#preview {
    display:flex;
    flex-direction:column;
    width:100%;
    min-height:40vw;
    height:calc(80vh - 300px);
}

iframe {
    width:100%;
    height:100%;
    border:0;
}

.react-tabs {
    display:flex;
    flex-direction:column;
    height:100%;
}

.tab-panel-container {
    height:100%;
    border-left:1px solid #aaa;
    border-right:1px solid #aaa;
    border-bottom:1px solid #aaa;
}

.react-tabs__tab-panel {
    height:100%;
}

.react-tabs__tab-list {
    margin-bottom:0;
}

.sticky-footer {
    position:fixed;
    left:0;
    right:0;
    bottom:0;
    padding:30px;
    background-color:white;
    border-top:1px solid #aaa;
}
