.main-footer {
    background-color:#326cf2;
    padding:10px 0;
    margin-top:auto;
    color:white;
    text-align:center;

    a {
        color:white;

        &:hover, &:focus {
            color:white;
        }
    }
}
