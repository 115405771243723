.panel {
    :hover, :focus {
        .panel-image-container {
            background-color:black;

            img {
                opacity:0.7;
            }

            .pills {
                display:flex;
            }
        }
    }

    .panel-image-container {
        position:relative;

        .pills {
            display:none;
            position:absolute;
            top:0;
            width:100%;
            height:100%;
            justify-content:center;

            li {
                align-self:center;
                text-align:center;
            }
        }
    }
}
