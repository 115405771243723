.site-header {
    padding:30px;
    margin-bottom:10px;
    background-color:#326cf2;
    text-align:center;

    a {
        color:white;

        &:hover, &:focus {
            color:white;
            text-decoration:none;
        }
    }

    // h1 {
    //     font-family:'Arimo', sans-serif;
    //     font-size:60px;
    // }

    img {
        min-width:300px;
        width:100%;
        max-width:80%;
    }
}
