.pills {
    padding:10px;
    margin:0;

    li {
        display:inline-block;
        list-style:none;
        background-color:#f5f5f5;
        padding:10px 20px;
        margin:10px;
        border-radius:5px;
    }
}
