@import '../..//node_modules/react-tabs/style/react-tabs.scss';

@import "components/header";
@import "components/footer";
@import "components/preview";
@import "components/panels";
@import "components/pills";

// @font-face {
//   font-family: 'Arimo';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: local('Arimo Bold'), local('Arimo-Bold'), url('/fonts/Arimo-Bold.ttf') format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

section {
    margin:20px 0;
}

main {
    section:last-child {
        margin-bottom:0;
    }
}

p, li {
    font-size:20px;
}
